




import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "users"
})
export default class Users extends Vue {}
